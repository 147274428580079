
.board-list-gallery {
    width: 100%;
    border-top:2px solid var(--v-primary-base);
}

.thum-wrap{
    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc((154 / 245) * 100%);
}
.thum{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.board-list-gallery__tit{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
